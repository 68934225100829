<template>
	<div class="content">
		<div class="screen-bg" :class="bgClass"></div>
		<div class="text">
			<div class="text-1 swiper-no-swiping"><span>{{text1}}</span></div>
			<div class="text-2 din swiper-no-swiping"><span>{{text2}}</span></div>
			<div class="text-3 swiper-no-swiping"><span v-html="text3"></span>
			</div>
			<div class="text-4 swiper-no-swiping"><span>{{text4}}</span></div>
			<div class="text-button"><a :href="more_link" target="_blank">了解更多</a></div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			content: {
				type: Object,
				default: () => {}
			}
		},
		computed: {
			bgClass() {
				return this.content.bgClass || ''
			},
			text1() {
				return this.content.text1 || ''
			},
			text2() {
				return this.content.text2 || ''
			},
			text3() {
				return this.content.text3 || ''
			},
			text4() {
				return this.content.text4 || ''
			},
			more_link() {
				return this.content.more_link || '#'
			}
		}
	}
</script>

<style>
</style>